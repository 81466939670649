import angular from 'angular';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';

import { selectCurrentSelection } from '@skryv/core-ng1/core/store/selectors/selections';
import { createNestedSearchCriterion, parseFieldDefinition } from '@skryv/search-client/searchESFields';
import { fetchExpertSummaryInformation } from '@skryv/bundle/customizations/actions/vrfExpertise';
import taskDefinitionKeys from '@skryv/bundle/customizations/constants/taskDefinitionKeys';
import expertTeamOrder from '@skryv/bundle/customizations/constants/expertTeamOrder';
import assignmentSize from '@skryv/bundle/customizations/constants/assignmentSizeOfExpertiseTypes';

import template from './AssignExpertiseSummary.html';
import './AssignExpertiseSummary.scss';

const namespace = 'vrf/components/expertise/vrfAssignExpertiseSummary';

const BUILDINGS = 'BUILDINGS';
const AGRICULTURE = 'AGRICULTURE';
const VEHICLES = 'VEHICLES';

angular
  .module(namespace, [])
  .component('vrfAssignExpertiseSummary', {
    template,
    controller: function($ngRedux, searchBackend) {
      'ngInject';

      const $ctrl = this;
      const disconnect = $ngRedux.connect(mapStateToThis, { fetchExpertSummaryInformation })(this);
      this.$onDestroy = disconnect;

      this.$onInit = () => {
        this.fetchExpertSummaryInformation().then(({ api }) => {
          this.expertInformation = api.response.data;
          this.transformedExpertInformation = transformExpertInformation(this.expertInformation);
        });

        // the current selection contains assignees and dossier ids; so we cannot derive for which type the assignee/contact got a task selected
        // we therefore retrieve all 'do expertise tasks' from ES so we can match them based on their dossier id and derive the type
        // wrap in a timeout to do this after everything else
        setTimeout(() => {
          queryDossiersForType(taskDefinitionKeys['expertise_' + BUILDINGS.toLowerCase()]).then((result) => this.dossiersForBuilding = result);
          queryDossiersForType(taskDefinitionKeys['expertise_' + AGRICULTURE.toLowerCase()]).then((result) => this.dossiersForAgriculture = result);
          queryDossiersForType(taskDefinitionKeys['expertise_' + VEHICLES.toLowerCase()]).then((result) => this.dossiersForVehicles = result);
        });
      };

      let queryDossiersForType = (taskKey) => {
        const select = [ parseFieldDefinition('dossier.id') ];
        const where = [ createNestedSearchCriterion([
          parseFieldDefinition('task.taskDefinitionKey').match(taskKey),
          parseFieldDefinition('task.active').match(true)
        ], 'task') ];
        return searchBackend.searchESForTask({ select, where, sort: [], paginationSize: 1000 }) // high pagination to fetch (probably) all
          .then((result) => result.items.map((item) => get(item,['dossier','id']))); // we only keep the dossier ids
      };

      let transformExpertInformation = (expertInformation) => {
        // define the order of the parties
        const partyOrderNumber = (typeKey) => (party) => {
          const index = indexOf(expertTeamOrder['order_' + typeKey.toLowerCase()],party.teamKey);
          return index < 0 ? undefined : index;
        };

        // build the information for each type
        const buildings = { label: 'Buildings', key: BUILDINGS, assignmentSize: assignmentSize['assignment_size_' + BUILDINGS.toLowerCase()], icon: 'vrf-icon-' + BUILDINGS.toLowerCase(), parties: sortBy(filter(expertInformation, (party) => party.taskAmountByType[BUILDINGS] !== undefined),partyOrderNumber(BUILDINGS)) };
        const agriculture = { label: 'Agriculture', key: AGRICULTURE, assignmentSize: assignmentSize['assignment_size_' + AGRICULTURE.toLowerCase()], icon: 'vrf-icon-' + AGRICULTURE.toLowerCase(), parties: sortBy(filter(expertInformation, (party) => party.taskAmountByType[AGRICULTURE] !== undefined),partyOrderNumber(AGRICULTURE)) };
        const vehicles = { label: 'Vehicles', key: VEHICLES, assignmentSize: assignmentSize['assignment_size_' + VEHICLES.toLowerCase()], icon: 'vrf-icon-' + VEHICLES.toLowerCase(), parties: sortBy(filter(expertInformation, (party) => party.taskAmountByType[VEHICLES] !== undefined),partyOrderNumber(VEHICLES)) };
        return [ buildings, agriculture, vehicles ];
      };

      this.prettyInitials = (teamLabel) => teamLabel.split(' ').map((word) => word[0]).join('').toUpperCase();
      this.prettySelectionNumber = (type,party) => {
        if(!this.selection) return '';
        if(this.statusForParty(type,party) === 'closed') return ' + /';
        else return '+' + get(this.selection,[party.teamKey,type.key],0);
      };

      this.statusForParty = (type,party) => {
        const currentNbOfSelectedTasks = this.selection ? get(this.selection,[party.teamKey,type.key],0) : 0;
        if(party.taskAmountByType[type.key] >= type.assignmentSize && currentNbOfSelectedTasks === 0) return 'closed';
        // we don't want to select new dossiers when the team still has enough dossiers assigned - however, the assigner can do so if he/she thinks that is appropriate - we indicate this by a warning state
        else if(party.taskAmountByType[type.key] >= type.assignmentSize && currentNbOfSelectedTasks > 0) return 'invalid-state';
        else if(party.taskAmountByType[type.key] < type.assignmentSize && currentNbOfSelectedTasks === 0) return 'open-empty';
        else if(party.taskAmountByType[type.key] < type.assignmentSize && currentNbOfSelectedTasks > 0 && currentNbOfSelectedTasks < type.assignmentSize) return 'open-between';
        else if(party.taskAmountByType[type.key] < type.assignmentSize && currentNbOfSelectedTasks === type.assignmentSize) return 'open-full';
        // we don't want to select more dossiers than allowed - however, the assigner can do so if he/she thinks that is appropriate - we indicate this by a warning state
        else if(party.taskAmountByType[type.key] < type.assignmentSize && currentNbOfSelectedTasks > type.assignmentSize) return 'invalid-state';
      };

      function mapStateToThis(state) {
        if(!$ctrl.expertInformation) return {};

        function selectionPerTypeAndParty(selection) {
          if(!$ctrl.expertInformation || !selection) return;
          // if we don't know which dossiers result to which type, don't do anything yet
          if(!$ctrl.dossiersForBuilding || !$ctrl.dossiersForAgriculture || !$ctrl.dossiersForVehicles) return;

          // For each team calculate how many dossiers of each type have been selected so far
          const selectionPerTeam = {};
          forEach(selection,((selectionForDossier) => {
            // derive type of selected assignment
            const buildingAddition = $ctrl.dossiersForBuilding.indexOf(selectionForDossier.dossierId) !== -1 ? 1 : 0;
            const agricultureAddition = $ctrl.dossiersForAgriculture.indexOf(selectionForDossier.dossierId) !== -1 ? 1 : 0;
            const vehicleAddition = $ctrl.dossiersForVehicles.indexOf(selectionForDossier.dossierId) !== -1 ? 1 : 0;

            forEach(selectionForDossier.teams,(team) => {
              return selectionPerTeam[team.key] = {
                all: get(selectionPerTeam,[team.key,'all'],0) + 1,
                BUILDINGS: get(selectionPerTeam,[team.key,BUILDINGS],0) + buildingAddition,
                AGRICULTURE: get(selectionPerTeam,[team.key,AGRICULTURE],0) + agricultureAddition,
                VEHICLES: get(selectionPerTeam,[team.key,VEHICLES],0) + vehicleAddition,
              };
            });
          }));
          return selectionPerTeam;
        }

        return {
          selection: selectionPerTypeAndParty(selectCurrentSelection(state))
        };
      }
    }
  });

export { template };
export default namespace;